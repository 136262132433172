import React, { useState, useEffect } from "react"

// Temporary Data
import BrandLogo from "../../images/resiland-logo.svg";

const LoaderLogo = () => {
    return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    )
}

export default LoaderLogo