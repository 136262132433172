import React, {useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import {useLocation} from "@reach/router"
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
  } from "react-share";

// Styles
import "./ArticleDetails.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import {ImageModule} from '../../../modules/Image_Module'

// Social Icons
import twitter from '../../../images/social-icons/twitter.svg'
import facebook from '../../../images/social-icons/facebook.svg'
import instagram from '../../../images/social-icons/instagram.svg'
import email_icon from '../../../images/social-icons/mail.svg'
import share from '../../../images/social-icons/share.svg'


const ArticleDetails = ( { details } ) => {

    const location = useLocation();
    const shareurl = location.href;
    
    const [Shareicons,setShareicons] = useState(false);

    const trackerShare = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'Share - social icons',
        'formType': event + ' - ' +shareurl,
        'formId': 'Share',
        'formName': 'Share',
        'formLabel': 'Share'
        });    
    }

    let tags_arr = details.Tags ? details.Tags?.toString()?.split(',') : [];

    let processedImages = JSON.stringify({});
    if (details?.imagetransforms?.Image_Transforms) {
        processedImages = details?.imagetransforms?.Image_Transforms;
    }

    return (
        <SectionWrapper className="article-details-wrapper">
            <Container>
                <Row>
                    <Col
                        lg={ {
                            span: 8,
                            offset: 2
                        } }
                    >
                        <div className="article-header">
                            <h1 className="general-heading">{details.Title}</h1>

                            <p className="written-by">
                                {details.Author && <span>Written by <a href="javascript:;">{details.Author}</a> . </span>}
                                {tags_arr.length > 0 && 
                                    <span>
                                    {
                                        tags_arr.map( ( hashTagsItem, hashTagsIndex ) => {
                                            return <>
                                                { "#"+hashTagsItem.trim() }{hashTagsIndex < (tags_arr.length-1) ? ", " : ""}
                                            </>
                                        } )
                                    }
                                    </span>
                                }
                            </p>
                            <div className="social-icons">
                                {/* {
                                    socialIcons.map( ( socialIconsItem, socialIconsIndex ) => {
                                        const { name, link, imageSource } = socialIconsItem;
                                        return (
                                            <a
                                                key={ socialIconsIndex }
                                                className="no-decoration"
                                                href={ link || '#' }
                                            >
                                                <img
                                                    src={ require( `../../../images/social-icons/${ imageSource }` ).default }
                                                    alt={ name }
                                                />
                                            </a>
                                        )
                                    } )
                                } */}

                                
                                <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share no-decoration">
                                    <img
                                        src={twitter}
                                        alt={"twitter"}
                                    />
                                </TwitterShareButton>
                                
                                <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share no-decoration">
                                    <img
                                        src={facebook}
                                        alt={"facebook"}
                                    />
                                </FacebookShareButton>

                                <EmailShareButton onClick={()=>trackerShare('EmailShareButton')} url={shareurl} className="my-share-button mail-share no-decoration">
                                    <img
                                        src={email_icon}
                                        alt={"Mail"}
                                    />
                                </EmailShareButton>

                            </div>
                        </div>
                        <div className="featured-image">
                            <ImageModule ImageSrc={details.Image} classNames={"card-img-top"} />
                            {/* <ImageModule ImageSrc={details.Image} classNames={"card-img-top"} imagetransforms={processedImages} renderer="srcSet" imagename={`news-and-insights.Image.detail_image`} strapi_id={details.id} /> */}
                        </div>
                        <div className="article-body">
                            {details.Content && HTMLReactParser(details.Content)}

                            {details.Modules && details.Modules.length > 0 && details.Modules.map((item, index)=>{
                                return(
                                    <>
                                        {item.Heading && <h2 className="content_heading">{item.Heading}</h2>}
                                        {item.Image && <ImageModule ImageSrc={item.Image} classNames="article_img" />}
                                        {item.Content && HTMLReactParser(item.Content)}
                                    </>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default ArticleDetails
