import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

// Styles
import './SectionHeader.scss';

// Components
import CustomLink from '../../components/CustomLink';

const SectionHeader = ( { sectionHeaderData, className } ) => {

	const { Title, SubTitle, Link, showSubTitleAlways = false, isInline = false } = sectionHeaderData;
	const { Text, ShortText, FullText, Href } = Link || {};

	return (
		<Row className={ `section-header-wrapper ${ className || '' }` }>
			<Col className={ `section-header-content ${ isInline ? 'section-header-inline' : '' }` }>
				<h2 className="general-heading">
					{ Title }
				</h2>
				{ SubTitle && (
					<p className={ `general-subheading ${ !showSubTitleAlways ? 'd-none d-sm-none d-md-block d-lg-block' : '' }` }>
						{ SubTitle }
					</p>
				) }
			</Col>
			{ Link && Link.Href && (
				<Col lg={ 5 } sm={ 3 } xs={ 4 } className="header-link-wrapper">
					<CustomLink
						caretPosition="right"
						href={ Href }
					>
						{ Text ? (
							<span>
								{ Text }
							</span>
						) : (
							<>
								<span className="d-none d-sm-none d-lg-block">
									{ FullText }
								</span>
								<span className="d-lg-none d-sm-block">
									{ ShortText }
								</span>
							</>
						) }

					</CustomLink>
				</Col>
			) }
		</Row>
	);
};

export default SectionHeader;
