import * as React from "react"
import { Link } from "@reach/router";

// Styles
import "./CustomLink.scss"

const CustomLink = ( customLinkData ) => {

    const { caretPosition, href, variant, children, onClick } = customLinkData;

    let imageSource = 'caret-right-icon.png';

    const getCaretImage = ( caretPosition ) => {
        if ( caretPosition === "right" ) {
            imageSource = 'caret-right-icon.png';
        }
        return imageSource;
    }

    return (
        <>
            { caretPosition ? (
                <span className="custom-link with-caret">
                    <img
                        src={ require( `../../images/${ getCaretImage( caretPosition ) }` ).default }
                        alt={ `Caret ${ caretPosition }` }
                    />
                    <a href={ href }>
                        { children }
                    </a>
                </span>
            ) : (
                href === "javascript:;" ?
                <a href={ href } className={ `custom-link without-caret ${ variant || '' }` } onClick={onClick}>
                    { children }
                </a>
                :
                <Link to={ href } className={ `custom-link without-caret ${ variant || '' }` }>
                    { children }
                </Link>
            ) }
        </>
    )
}

export default CustomLink
