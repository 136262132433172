import React from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './FooterBanner.scss';

// Components
import WorkWithUs from "./WorkWithUs";
import MailingList from "./MailingList";

const FooterBanner = ( { type, Module } ) => {
    return (
        <React.Fragment>
            {
                ( type === "work-with-us" ? (
                    <WorkWithUs Module={Module}/>
                ) : ( type == "mailing-list" ) ? (
                    <MailingList Module={Module}/>
                ) : <div>Test</div> )
            }

        </React.Fragment>
    )
}

export default FooterBanner
