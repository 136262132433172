import * as React from "react"
import {Link} from "gatsby"
import { Row, Col, Card } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"
import dateFormat from "dateformat";

// Styles
import {ImageModule} from '../../modules/Image_Module'
import {CustomLinks} from '../../components/common/menuUtils'

import "./SingleNewsCard.scss"


const SingleNewsCard = (singleNewsCardData) => {

    const { Image, URL, isHighlightCard } = singleNewsCardData;

    var news_link = `/${CustomLinks.news_media}/${URL}/`;

    const ImageRenderList = ({item, image_type}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.Image_Transforms) {
            processedImages = item?.imagetransforms?.Image_Transforms;
        }

        return (
            <ImageModule ImageSrc={item.Image} classNames={"card-img-top"} imagetransforms={processedImages} renderer="srcSet" imagename={`news-and-insights.Image.${image_type}`} strapi_id={item.id} />                           
        )
    }

    return (
        <div className="single-news-card-wrapper">
            <Card className={ isHighlightCard ? "highlight-card" : "" }>
                {
                    ( !isHighlightCard ? (
                        <>
                            <div class="card-container-image">
                                <Link to={`${news_link}`}>
                                    {/* <ImageModule ImageSrc={Image} classNames="card-img-top" /> */}
                                    <ImageRenderList item={singleNewsCardData} image_type="tile_small_image" /> 
                                </Link>
                            </div>

                            <CardBody
                                cardData={ singleNewsCardData }
                                news_link={news_link}
                            />
                        </>
                    ) : (
                        <Row>
                            <Col lg="7">
                                <div class="card-container-image">
                                    <Link to={`${news_link}`}>
                                        {/* <ImageModule ImageSrc={Image} classNames="card-img-top" /> */}
                                        <ImageRenderList item={singleNewsCardData} image_type="tile_large_image" /> 
                                    </Link>
                                </div>

                            </Col>
                            <Col className="d-flex align-items-center">
                                <CardBody
                                    cardData={ singleNewsCardData }
                                    news_link={news_link}
                                />
                            </Col>
                        </Row>
                    ) )
                }
            </Card>
        </div>
    )
}

export default SingleNewsCard

const CardBody = ( { cardData = {}, news_link } ) => {

    const { Title, Published_Date, Intro_Text, Content, Tags, isHighlightCard } = cardData;

    let tags_arr = Tags ? Tags?.toString()?.split(',') : [];

    return (
        <Card.Body>
            {Published_Date && 
            <Card.Text className="published-date">
                { dateFormat(Published_Date, "d mmmm yyyy") }
            </Card.Text>
            }
            
            <Card.Title>
                <Link to={`${news_link}`}>{ Title }</Link>
            </Card.Title>
            {
                ( isHighlightCard && Content ) && (
                    <span className="description">
                        <Card.Text>
                            { HTMLReactParser(Content) }
                        </Card.Text>
                    </span>
                )
            }
            {tags_arr.length > 0 && 
                <Card.Text className="hash-tags">
                    <span>
                    {
                        tags_arr.map( ( hashTagsItem, hashTagsIndex ) => {
                            return <>
                                { "#"+hashTagsItem.trim() }{hashTagsIndex < (tags_arr.length-1) ? ", " : ""}
                            </>
                        } )
                    }
                    </span>
                </Card.Text>
            }
            
        </Card.Body>
    )
}