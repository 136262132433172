import * as React from 'react';
import { Col, Container, Row } from "react-bootstrap";

// Components
import SectionWrapper from "components/SectionWrapper";
import SectionHeader from "components/SectionHeader";
import SingleNewsCard from "components/SingleNewsCard";


import {SimilarNews} from "../../../queries/common_use_query";
import {CustomLinks} from '../../../components/common/menuUtils'

// Styles
import './SimilarNews.scss';

const SimilarNewsModule = (props) => {

    //let tags_arr = props.Tags ? props.Tags?.toString()?.split(',') : [];

    //console.log("news_categories", props.news_categories[0]);

    const { data } = SimilarNews(props?.news_categories[0]?.Title, props.article_id);

    const sectionHeaderData = {
        Title: "Similar News",
        Link: {
            Text: 'View All',
            Href: '/'+CustomLinks.news_media+'/'
        }
    }

    const layout = {
        lg: 4,
        sm: 6,
        xs: 12
    };

    return (
        <>
            {data && data?.newsAndInsights?.length > 0 &&  (
       
        <SectionWrapper className="similar-news-wrapper" >
            
            <Container>
                <div className='similar-news-header-section'>
                    <SectionHeader
                        sectionHeaderData={ sectionHeaderData }
                    />
                </div>
                <div className="sub-section-wrapper similar-news-body-section">
                    {/* To do: make a generic component like property cards */ }
                    <div className="similar-news-cards-wrapper">
                        
                                <Row>
                                    {
                                        data?.newsAndInsights.map( ( newsItem, newsIndex ) => {

                                            const getClassName = ( newsIndex ) => {
                                                if ( newsIndex > 0 && newsIndex < 2 ) {
                                                    return 'hide-on-tab-and-mobile';
                                                } else if ( newsIndex > 0 ) {
                                                    return 'hide-on-mobile';
                                                }
                                                return '';
                                            }

                                            return (
                                                <Col
                                                    key={ newsIndex }
                                                    { ...layout }
                                                    className={ getClassName( newsIndex ) }
                                                >
                                                    <SingleNewsCard
                                                        { ...newsItem }
                                                    />
                                                </Col>
                                            )
                                        } )
                                    }
                                </Row>
                           
                    </div>
                </div>
            </Container>
            
        </SectionWrapper>
        )}
        </>
    );
};

export default SimilarNewsModule;
