import React from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './MailingList.scss';

// Components
import CustomButton from "components/CustomButton";
import CustomTextField from "components/CustomTextField";

import NewsletterForm from "../../forms/newsletter-form";

const MailingList = ( { mailingListData } ) => {
    return (
        <div className="mailing-list-wrapper footer-banner-wrapper">
            <Container>
                <Row>
                    <Col
                        xl="6"
                        lg="12"
                        className="mailing-list-content"
                    >
                        <h2 className="general-heading">
                            Join the Resiland mailing list
                        </h2>
                        <p className="mb-0">
                            Subscribe to our newsletter to receive the latest updates.
                        </p>
                    </Col>
                    <Col
                        xl="6"
                        lg="12"
                        className="d-flex align-items-end"
                    >

                        <NewsletterForm />

                        {/* <CustomTextField
                            variant="white"
                            label="EMAIL ADDRESS"
                            defaultValue="christopher@starberry.tv"
                        />
                        <div className="button-container">
                            <CustomButton
                                variant="white"
                                value="SUBSCRIBE"
                            />
                        </div> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default MailingList
