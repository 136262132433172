import React, { useState, useEffect } from "react"

// Temporary Data
import newsData from "../data/news-details.json"

// Components
import {CustomLinks} from "../components/common/menuUtils"
import Layout from "../components/layout"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import ArticleDetails from "layout/NewsDetails/ArticleDetails"
import SimilarNews from "layout/NewsDetails/SimilarNews"

import LoaderLogo from "../components/LoaderLogo"

import Newsletter from "../components/FooterBanner/"

import {GetNewsDetails} from "../queries/common_use_query";

const PropertyDetailsTemplate = (props) => {
    const { loading, error, data } = GetNewsDetails(props.pageContext?.slug);
    const [ details, setGetDetails ] = useState({});

    useEffect(()=>{
        data && data.newsAndInsights.length > 0 && setGetDetails(data.newsAndInsights[0])
    },[data])
    
    // Loading logo 
    if (loading) return (
        <LoaderLogo />
    );
    // Loading logo

    //console.log("data", details)

    return (
        <React.Fragment>
            {details && details.Title &&
                <Layout template="property_details" Meta_Title={details.Title} Meta_Description={details.Title}>
                    <BreadCrumbs
                        breadCrumbsData={ 
                            [{
                                name: 'Home',
                                link: '/'
                            },
                            {
                                name: "About Us",
                                link: "/"+CustomLinks.about+"/"
                            },
                            {
                                name: "News & Insights",
                                link: "/"+CustomLinks.news_media+"/"
                            },
                            {
                                name: details.Title,
                                // link: '#'
                            }]
                        }
                    />
                    <ArticleDetails details={details} />

                    <SimilarNews Tags={details.Tags} article_id={details.id} similarNewsData={ newsData.SimilarNews} news_categories={details?.news_categories} />

                    <Newsletter type="mailing-list" />
                    
                </Layout>
            }
            
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate