import React from "react"
import { Col, Container, Row } from "react-bootstrap";

// Styles
import './WorkWithUs.scss';

// Components
import CustomButton from "components/CustomButton";

const WorkWithUs = ( { Module } ) => {
    return (
        <>
        { Module && 
        <div className="work-with-us-wrapper footer-banner-wrapper">
            <Container>
                <Row>
                    <Col
                        lg="7"
                        md="12"
                        className="work-with-us-content"
                    >
                        <h2 className="general-heading">
                            {Module.Title}
                        </h2>
                        {Module.Content && 
                            <p className="mb-0">
                                {Module.Content}
                            </p>
                        }
                        
                    </Col>
                    <Col
                        xl={ { span: 5} }
                        md={ { span: 12 } }
                        sm={ { span: 12 } }
                    >
                        <div className="button-container">
                            {Module.CTA_Label_1 && 
                                <CustomButton
                                    variant="white"
                                    value={Module.CTA_Label_1}
                                    link={Module.CTA_Link_1}
                                />
                            }

                            {Module.CTA_Label_2 && 
                                <CustomButton
                                    variant="white"
                                    value={Module.CTA_Label_2}
                                    link={Module.CTA_Link_2}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        }
    </>
    )
}

export default WorkWithUs
